.bg-top {
    background: linear-gradient(to bottom, transparent 15%, white 90%), url('../assets/bg-top-4.png');
    background-size: cover;
    background-position: center;
    height: 500px; /* Increased height for better visibility */
}

.bg-bottom {
    background: linear-gradient(to top, transparent 15%, white 90%), url('../assets/bg-bottom.png');
    background-size: cover;
    background-position: center;
    height: 500px; /* Consistent with the top section */
}

.whitish-area {
    background-color: #FFF;
    height: 180px; /* Adjusted height for more white space */
}

.yellow-line {
    border-color: #FFCB1A; /* This is a yellow color based on your existing color codes */
}

.marquee {
    position: relative;
    overflow: hidden;
    height: 250px; /* Adjust based on your needs */
  }
  
  .marquee h1 {
    position: absolute;
    /* width: 100%;
    height: 100%; */
    margin: 0;
    line-height: 50px; /* Same as container height */
    animation: marquee 10s linear infinite;
  }
  
  @keyframes marquee {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(-100%);
    }
  }
  